import React, { useState, useEffect } from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import LazyLoad from 'react-lazyload';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Page from '../components/Page';
import LogosBlock from '../components/Logos/block';
import Location from '../components/Location';

import styles from './the-team.module.css';

function sortAlphabetically(team) {
  team.sort((a, b) => a.name > b.name ? 1 : -1);
  return team;
}

function shuffle(array) {
  const rv = [...array];
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    [rv[currentIndex], rv[randomIndex]] = [rv[randomIndex], rv[currentIndex]];
  }

  return rv;
}

function renderTeamMember({ name, title, image, email }) {
  return (
    <li key={name} className={styles.item}>
      <LazyLoad>
        <img
          className={styles.avatar}
          width="160"
          height="160"
          src={image || '/images/team/placeholder.png'}
          alt={`${name} - ${title}`}
        />
      </LazyLoad>
      <span className={styles.name}>{name}</span>
      <span className={styles.title}>{title}</span>
    </li>
  );
}

const TheTeamPage = ({ data }) => {
  const [shuffledTeam, setShuffledTeam] = useState(null);

  const team = data.allTeamJson.edges.map(({ node }) => ({
    ...node,
  }));

  useEffect(() => {
    /**
     * Shuffle the team page.
     */
    setShuffledTeam(sortAlphabetically(team));
  }, [data]);

  return (
    <div>
      <Helmet
        title={`${data.site.siteMetadata.title} - The team`}
        meta={[
          {
            name: 'description',
            content:
              'To learn what we don’t know. To solve hard challenges. To enjoy the journey.',
          },
          {
            name: 'keywords',
            content:
              'javascript, js, consulting, big data, hadoop, spark, machine learning, tensor flow, react, angular, web, html, css, design, ux',
          },
        ]}
      >
        <html lang="sv" />
        <link rel="icon" href="/favicon.ico?v=2" />
      </Helmet>
      <div>
        <Header className={styles.headerBackground} />
        <Page bgColor="#F8F6FA">
          <section>
            <h1>The team</h1>
            <h2>Meet our team of enthusiasts</h2>
            <p>
              We're a group of software developers & designers highly passionate
              for machine learning and data analysis. We're constantly on the
              lookout for new colleagues, feel free to have a look at our{' '}
              <Link className={styles.link} to="/careers#openings">job offers</Link>.
            </p>
          </section>
          <main>
            <div className={styles.line} />
            <ul className={styles.list}>
              {(shuffledTeam || team).map(renderTeamMember)}
            </ul>
          </main>
        </Page>
        <LogosBlock
          id="logos"
          logos={data.allLogosJson.edges.map((x) => x.node)}
        />
        <Location title={data.site.siteMetadata.title} id="location" />
        <Footer
          products={data.allProductsJson.edges.map((x) => x.node)}
          cases={data.allCasesJson.edges.map((x) => x.node)}
          title={data.site.siteMetadata.title}
        />
      </div>
    </div>
  );
};

export default TheTeamPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allTeamJson {
      edges {
        node {
          name
          title
          image
          email
        }
      }
    }
    allLogosJson {
      edges {
        node {
          src
          alt
        }
      }
    }
    allCasesJson {
      edges {
        node {
          url
          shortTitle
        }
      }
    }
    allProductsJson {
      edges {
        node {
          url
          shortTitle
        }
      }
    }
  }
`;
